import WEBEXTEND from '../constants/webextend';
import WPSLog from '../utils/console';
import { storageFactory } from '../utils/storageFactory';

const WP_LS_ID_EMAILHASH_KEY = '_wp_eh';
const WP_LS_ID_CUSTOMERID_KEY = '_wp_ci';
const WP_LS_ID_EMAILHASH_KEY_NEXT = '_wp_eh_2';
const WP_LS_ID_CUSTOMERID_KEY_NEXT = '_wp_ci_2';

const storage = storageFactory();

let WPMERCHANTID = null;

let toLocalStorage = function(key, idValue) {
  let value = JSON.stringify({
    // timestamp of last association
    timestamp: Date.now(),
    value: idValue
  });
  storage.setItem(key, value);
};

let fromLocalStorage = function(key) {
  let record = storage.getItem(key);
  if (record !== undefined && record !== null) {
    return JSON.parse(record);
  }
  return record || null; // don't return undefined
};

let getAndRemoveOld = function(key) {
  let val = fromLocalStorage(key);
  if (val) {
    storage.removeItem(key);
    val = val.value;
  }
  return val;
};

let getAndRemoveMerchantBased = function(key) {
  let merchantId = getWpMerchantId();

  let val = fromLocalStorage(`${key}-${merchantId}`);
  if (val) {
    storage.removeItem(`${key}-${merchantId}`);
    if (val.value.value) {
      val = val.value.value;
    } else {
      val = val.value;
    }
  }

  return val;
};

let addVisitorIdentification = function(predictInfo) {
  let oldEh = getAndRemoveOld(WP_LS_ID_EMAILHASH_KEY);
  let oldCi = getAndRemoveOld(WP_LS_ID_CUSTOMERID_KEY);
  let mEh = getAndRemoveMerchantBased(WP_LS_ID_EMAILHASH_KEY);
  let mCi = getAndRemoveMerchantBased(WP_LS_ID_CUSTOMERID_KEY);

  if (oldEh || mEh) {
    toLocalStorage(WP_LS_ID_EMAILHASH_KEY_NEXT, oldEh || mEh);
  }

  if (oldCi || mCi) {
    toLocalStorage(WP_LS_ID_CUSTOMERID_KEY_NEXT, oldCi || mCi);
  }

  let eh = fromLocalStorage(WP_LS_ID_EMAILHASH_KEY_NEXT);
  let ci = fromLocalStorage(WP_LS_ID_CUSTOMERID_KEY_NEXT);

  if (eh != null) {
    predictInfo.emailHash = eh.value;
  }
  if (ci != null) {
    predictInfo.customerId = ci.value;
  }

  WPSLog.info(predictInfo, 'predictInfo');
  return predictInfo;
};

let saveVisitorIdentification = function(txn) {
  // save last customer identifier (if any) to use as fallback
  // if consumer is using this browser but not logged in in the future.
  if (txn.emailHash !== undefined && txn.emailHash !== null) {
    toLocalStorage(WP_LS_ID_EMAILHASH_KEY_NEXT, txn.emailHash);
  }

  if (txn.customerId !== undefined && txn.customerId !== null) {
    toLocalStorage(WP_LS_ID_CUSTOMERID_KEY_NEXT, txn.customerId);
  }
};

function getSiteSection(txn) {
  if (!!txn.views) {
    return WEBEXTEND.PRODUCT_VIEW;
  }
  if (txn.searchTerm) {
    return WEBEXTEND.SEARCH_RESULTS;
  }
  if (txn.category && txn.category.length) {
    return WEBEXTEND.CATEGORY;
  }

  if (window.location.pathname === '/') {
    return WEBEXTEND.LANDING_PAGE;
  }
}

function saveEmailHash(emailHash) {
  toLocalStorage(WP_LS_ID_EMAILHASH_KEY_NEXT, emailHash);
  Scarab.setEmailHash(emailHash);
  Scarab.go();
}

function saveWpMerchantId(wpMerchantId) {
  WPMERCHANTID = wpMerchantId;
}

function getWpMerchantId() {
  return WPMERCHANTID;
}

function Predict(txn) {
  return addVisitorIdentification({
    siteSection: getSiteSection(txn),
    // customerId and / or emailHash have been saved to localStorage
    // thus there is no need to check for them in current txn.
    currency: (txn.currency || [])[0],
    transaction: txn,
    loggedIn: !!(txn.emailHash || txn.customerId)
  });
}

export {
  addVisitorIdentification,
  saveVisitorIdentification,
  saveEmailHash,
  saveWpMerchantId,
  getWpMerchantId,
  toLocalStorage,
  Predict
};
