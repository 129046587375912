import { Device } from './device.js';
import { Locale } from './locale.js';
import { Time } from './time.js';
import { UserAgent } from './useragent.js';
import { Predict } from './predict.js';
import store from '../utils/store';

const referrer = document.referrer;
const url = document.location.href;

export const Session = {
  getSession: (txn) => {
    store.set(Object.assign({},
      Device.device(),
      Device.plugins(),
      UserAgent.detect(),
      {
        architecture: Device.architecture(),
        timezone: Time.timezone(),
        hostname: window.location.hostname,
        referrer,
        url
      }, Locale.locale()));

    // eslint-disable-next-line new-cap
    const predictResult = Predict(txn);
    let result = Object.assign({}, predictResult);
    delete result.transaction;
    store.set(result);

    return {
      device: {
        device: Device.device(),
        plugins: Device.plugins(),
        architecture: Device.architecture()
      },
      locale: Locale.locale(),
      'useragent': UserAgent.detect(),
      time: {
        timezone: Time.timezone()
      },
      hostname: window.location.hostname,
      predict: predictResult
    };
  }
};
