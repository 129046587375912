import '../scss/popup.scss';
import Creative from '../data/creative.js';
import wpContent from '../wpcontent/index.js';
import EVENTS from '../constants/events.js';
import { InlineOverlay } from './html/inlineOverlay';

export default {
  init: {
    value: function() {
      this.overlay = new InlineOverlay(
        window.document,
        () => this.log(EVENTS.CANCEL),
        this.getNamespaceId());

      let fragments = this.renderInfo.content.map(content =>
        wpContent.wpContentToHtml(content, this.getNamespaceId(), this.config));

      this.overlay.attachToDom();

      this.creative = Creative.createCreative('#wps_popup [data-wps-popup-content-body]',
        fragments,
        this, true,
        Creative.REPLACE);
      this.creative.onEnd = () => this.overlay.close();
    }
  },


  _render: {
    value: async function() {
      const creative = await this.creative.alloc();
      creative.createGuardElement();
      creative.placeGuardElement();
      creative.render();

      await this.listen();
      if (isStaleAd(creative.ad, this.config)) {
        throw ({ id: 'ad is stale' });
      } else {
        this.overlay.show();
      }

      function isStaleAd(ad, config) {
        if (!config.default_config.spa_support) {
          return false;
        }
        return Window.emarsysCurrentRequestId !== ad.requestId;
      }
    }
  }
};
