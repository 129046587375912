export const FLIPPERS = {
  RANK_BASED_SORTING_ON_UI: 'rank_based_sorting_on_ui',
  GA_MULTI_TRACKER_SUPPORT: 'ga_multi_tracker_support',
  ALLOW_PARENT_ELEMENT_STYLING: 'allow_parent_element_styling'
};


export class Flippers {
  static isFlipperOn(moduleConfig, flipper) {
    return moduleConfig.default_config && moduleConfig.default_config[flipper];
  };
}
