import '../scss/ribbon.scss';
import Creative from '../data/creative';
import wpContent from '../wpcontent';
import EVENTS from '../constants/events';
import { InlineRibbon } from './html/inlineRibbon';

export default {
  init: {
    value: function() {
      this.ribbon = new InlineRibbon(
        window.document,
        this.renderInfo.options,
        () => this.log(EVENTS.CANCEL),
        this.getNamespaceId());

      let fragments = this.renderInfo.content.map(content =>
        wpContent.wpContentToHtml(content, this.getNamespaceId(), this.config));

      this.ribbon.attachToDom();

      this.creative = Creative.createCreative(this.ribbon.getContentSelector(),
        fragments,
        this, true,
        Creative.REPLACE);
      this.creative.onEnd = () => this.ribbon.close();
    }
  },

  _render: {
    value: function() {
      return this.creative.alloc().then(creative => {
        creative.createGuardElement();
        creative.placeGuardElement();
        creative.render();
      });
    }
  }
};
