import WPSLog from './console';

const readOnlyWindow = {
  document: {
    set cookie(c) {
      WPSLog.info('setCookie called' + c);
    },
    get cookie() {
      WPSLog.info('getCookie called');
      return window.document.cookie;
    },
    domain: window.document.domain
  }
};

let ScarabInstance = null;

export default class {
  static create() {
    if (ScarabModule) {
      // eslint-disable-next-line new-cap
      return ScarabModule(readOnlyWindow, {
        isNotNewPageView: true,
        jsonpStem: 'scarab_jsonp_webpersonalization',
        disableMultiGoCheck: true,
        doNotInspect: true
      });
    } else {
      return undefined;
    }
  }

  static getInstance() {
    if (!ScarabInstance) {
      ScarabInstance = this.create();
    }
    return ScarabInstance;
  }

  static clearInstance() {
    ScarabInstance = null;
  }
}
