import AbstractLoggerFrontend from '../../src/loggerFrontend/abstract';
import EVENTS from '../../src/constants/events';

const GTAG_CLIENT = 'GTAG';
const LEGACY_CLIENT = 'legacy_GA';

export default class GALoggerFrontend extends AbstractLoggerFrontend {

  constructor(_window) {
    super();
    this._window = _window;
  }

  // eslint-disable-next-line
  send(id, type, timestamp, params) {
    if (this._window.EMARSYS_GA_DISABLED) {
      return;
    }

    let gaClient = this.getGAClient(this._window);
    if (!gaClient) {
      return;
    }

    if (gaClient === LEGACY_CLIENT) {
      this.legacySend(type, id);
    } else if (gaClient === GTAG_CLIENT) {
      this.gtagSend(type, id);
    }
  }

  getGAClient(_window) {
    if (_window.gtag) {
      return GTAG_CLIENT;
    }

    if (_window.ga && typeof _window.ga.getAll === 'function') {
      return LEGACY_CLIENT;
    }

    return null;
  }

  nonInteractionEvent(type) {
    return ![EVENTS.CLICK, EVENTS.SUBMIT].includes(type);
  }

  legacySend(type, id) {
    let event = {
      eventCategory: 'Webmessage',
      eventAction: type,
      eventLabel: id
    };

    if (this.nonInteractionEvent(type)) {
      event.nonInteraction = true;
    }

    if (type === EVENTS.CLICK) {
      event.transport = 'beacon';
    }

    let trackers = this._window.ga.getAll();
    let self = this;
    trackers.forEach(function(tracker) {
      let trackerName = tracker.get('name');
      self._window.ga(trackerName + '.send', 'event', event);
    });
  }

  gtagSend(type, id) {
    let event = {
      event_category: 'Webmessage',
      event_action: type,
      event_label: id
    };

    if (this.nonInteractionEvent(type)) {
      event.non_interaction = true;
    }

    this._window.gtag('event', 'Webmessage', event);
  }
}
