import Events from '../../src/constants/events';

export default class AbstractLoggerFrontend {
  constructor() {
    this.subscribed = false;
  }

  listener({ change }) {
    if (change.lastAdStateChange) {
      const id = change.lastAdStateChange;
      const { type, timestamp, params } = change[id].state;
      if (this[type]) {
        this[type](id, timestamp, params);
      }
    }
  }

  // eslint-disable-next-line
  send(id, type, timestamp, params) {}

  [Events.IMPRESSION](id, timestamp, params) {
    this.send(id, Events.IMPRESSION, timestamp, params);
  }

  [Events.CANCEL](id, timestamp, params) {
    this.send(id, Events.CANCEL, timestamp, params);
  }

  [Events.CLICK](id, timestamp, params) {
    this.send(id, Events.CLICK, timestamp, params);
  }

  [Events.SUBMIT](id, timestamp, params) {
    this.send(id, Events.SUBMIT, timestamp, params);
  }
};
