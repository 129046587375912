import './utils/injectStyles';
import { Start } from './start';

function getMainObject(moduleConfig) {
  if (moduleConfig && moduleConfig.default_config && moduleConfig.default_config.spa_support) {
    const webPersonalization = require('./webpersonalization');
    return webPersonalization.default;
  } else {
    const legacyWebPersonalization = require('./legacyWebpersonalization');
    return legacyWebPersonalization.default;
  }
}

export function bootstrapFactory(_window) {
  return function() {
    const mainObject = getMainObject(_window._scwebpersonalization);
    new Start().start(mainObject, _window);
  };
}
