import { createDomFragment } from '../renderUtils';

export class InlineRibbon {
  constructor(document, options, closeCallback, namespaceId) {
    this._document = document;
    this._options = options;
    this._closeCallback = closeCallback;
    this._ribbon = null;
    const type = this._getType();
    this._elementId = `wps-ribbon_${type}`;
    this._namespaceId = namespaceId;
  }

  attachToDom() {
    let ribbon = this._document.querySelector(`#${this._elementId}`);
    if (ribbon) {
      this._ribbon = ribbon;
      return;
    }

    this._document.body.insertBefore(createDomFragment(this._getTemplate()), this._document.body.firstChild);
    ribbon = this._document.querySelector(`#${this._elementId}`);
    ribbon.dataset.wpsRibbonPlacement = this._options.placement;
    ribbon.dataset.wpsRibbonFloating = this._options.floating;
    this._ribbon = ribbon;
    this._attachCloseEvent();
  }

  show() {
    this._ribbon.classList.remove('wps-ribbon-hidden');
  }

  hide() {
    this._ribbon.classList.add('wps-ribbon-hidden');
  }

  close(triggerCallback = false) {
    this._ribbon.dataset.wpsRibbon = 'hidden';
    if (triggerCallback) {
      this._closeCallback();
    }
  }

  getId() {
    return this._elementId;
  }

  getContentSelector() {
    return `#${this._elementId} > [data-wps-ribbon-content-body]`;
  }

  _getType() {
    return `${this._options.placement}_${this._options.floating}`;
  }

  _attachCloseEvent() {
    const ribbonClose = this._ribbon.querySelector('[data-wps-ribbon-close]');
    if (!ribbonClose) {
      return;
    }

    ribbonClose.addEventListener('click', () => this.close(true));

    ribbonClose.addEventListener('keydown', event => {
      if (event.key === 'Enter') {
        this.close(true);
      }
    });
  }

  _generateCloseButtonId() {
    return `wps-ribbon-${this._options.placement.toLowerCase()}-${this._options.floating ? 'floating' : 'fix'}-close-button`;
  }

  _getTemplate() {
    return `
      <div id="${this._elementId}" data-wps-ribbon="" data-wps-ribbon-placement=""
          data-wps-ribbon-floating="" data-wps-namespace-id="${this._namespaceId}" data-wps-remove-on-reload>
        <div data-wps-ribbon-content-body></div>
        <div id="${this._generateCloseButtonId()}" data-wps-ribbon-close tabindex="1" aria-label="close" role="button"></div>
      </div>`;
  }
}
