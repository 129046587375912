import { createDomFragment } from '../renderUtils';

export class InlineOverlay {
  constructor(document, closeCallback, namespaceId) {
    this._document = document;
    this._closeCallback = closeCallback;
    this._namespaceId = namespaceId;
    this._popup = null;
  }

  attachToDom() {
    let popup = this._document.querySelector('#wps_popup');
    if (popup) {
      this._popup = popup;
      return;
    }

    this._document.body.appendChild(createDomFragment(this._getTemplate()));
    this._popup = this._document.querySelector('#wps_popup');
    this._attachCloseEvent();
  }
  show() {
    this._popup.dataset.wpsNamespaceId = this._namespaceId;
    this._popup.dataset.wpsPopup = '';
    this._popup.classList.remove('hide-popup');
  }

  hide() {
    this._popup.dataset.wpsPopup = 'hidden';
    this._popup.classList.add('hide-popup');
  }

  close(triggerCallback = false) {
    this._popup.dataset.wpsPopup = 'hidden';
    this._popup.classList.add('hide-popup');
    if (triggerCallback) {
      this._closeCallback();
    }
  }

  _attachCloseEvent() {
    const popupClose = this._popup.querySelector('[data-wps-popup-close-intent]');

    this._popup.addEventListener('click', event => {
      if (
        event.target === this._popup ||
        (event.target !== this._popup && popupClose && event.target.contains(popupClose))
      ) {
        this.close(true);
      }
    });

    if (popupClose) {
      popupClose.addEventListener('keydown', event => {
        if (event.key === 'Enter') {
          this.close(true);
        }
      });
    }

    this._document.addEventListener('keydown', this.escapeKeydownHandler());
  }

  escapeKeydownHandler() {
    return event => {
      if (event.key === 'Escape') {
        this.close(true);
      }
    };
  }

  _getTemplate() {
    return `
      <div id="wps_popup" data-wps-popup="hidden" data-wps-namespace-id="${this._namespaceId}" data-wps-remove-on-reload>
        <div data-wps-popup-content>
          <div id="wps-overlay-close-button" data-wps-popup-close data-wps-popup-close-intent tabindex="1" aria-label="close" role="button"></div>
          <div data-wps-popup-content-body="" class="bordered"></div>
        </div>
      </div>`;
  }
}
